<template>
    <div id="app">
        <div class="container">
            <h2 class="border-bottom pb-2 mb-3 text-center">
                <i class="fas fa-blog"></i> Bing Search
            </h2>
            <div class="search-container">
                <div class="widget-content widget-content-area component-card_1 mb-4">
                    <div class="row justify-content-center">
                        <div class="col-lg-8 col-md-10">
                            <div class="control-styles dependency-section">
                                <h6>Filter</h6>
                                <div class="add-dependency">
                                    <input
                                        class="e-control e-textbox e-outline e-lib e-input e-keyboard"
                                        placeholder="Add Filter"
                                        v-model="newDependency"
                                        @keyup.enter="addDependency"
                                    />
                                </div>
                                <ul class="dependency-list">
                                    <li v-for="(dependency, index) in dependencies" :key="index"
                                        class="dependency-item">
                                        {{ dependency }}
                                        <button data-v-865826d0="" class="btn btn-danger"
                                                @click="removeDependency(index)" type="button">
                                            <i data-v-865826d0="" title="Delete" class="fa fa-trash"></i>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <div class="tabs-wrap text-center mt-4">
                                <button
                                    class="e-control e-progress-btn e-lib e-btn e-spin-right"
                                    data-ripple="true"
                                    aria-label="SEARCH progress"
                                    style="color: white; background-color: rgb(56, 56, 56);"
                                    @click="filter"
                                >
                                    <span class="e-btn-content"><i class="fa fa-search" aria-hidden="true"></i></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Ícono de espera -->
            <div v-if="isLoading" class="loading-icon text-center">
                <i class="fa fa-spinner fa-spin fa-3x"></i>
            </div>
            <!-- Contenedor para resultados de búsqueda -->
            <div class="results-container">
                <!-- Título de los resultados -->
                <h4 class="text-center">History</h4>
                <!-- Contenido de los resultados -->
                <div class="search-results">
                    <div v-if="paginatedResults.length > 0">
                        <div v-for="(result, index) in paginatedResults" :key="index" class="result-item">
                            <!-- Fila para cada resultado -->
                            <div class="result-row">
                                <p class="result-date">{{ result.regdate }}</p>
                                <div class="result-info">
                                    <div class="result-info-inner">
                                        <p class="result-name">{{ result.search }}</p>
                                        <button class="btn btn-primary result-button" @click="viewDetails(result[2])">
                                            Details
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="text-center">no results.</div>
                </div>
                <div class="pagination-container text-center mt-4">
                    <button class="btn btn-primary" @click="prevPage" :disabled="currentPage === 1">Previous</button>
                    <span class="mx-2">{{ currentPage }}</span>
                    <button class="btn btn-primary" @click="nextPage" :disabled="currentPage === totalPages">Next
                    </button>
                </div>
            </div>
        </div>
        <!-- Modal para ver detalles -->
        <b-modal v-model="showModal" title="Details" @hide="clearModal" size="lg">
            <b-tabs content-class="mt-3">
                <b-tab title="Search">
                    <div v-for="(item, index) in selectedResult" :key="index">
                        <p><strong>Title:</strong> {{ item.title }}</p>
                        <div v-if="item.displayed_url">
                            <p><a :href="item.displayed_url" target="_blank">{{ item.displayed_url }}</a></p>
                        </div>
                        <div v-if="item.snippet">
                            <p><strong>Snippet:</strong> {{ item.snippet }}</p>
                        </div>
                    </div>
                </b-tab>
            </b-tabs>
        </b-modal>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            fields: {text: 'name', value: 'code'},
            city_fields: {text: 'city_name'},
            filters: {
                country: '',
                state: '',
                search: ''
            },
            searchResults: [], // Array para almacenar los resultados de la búsqueda SERP
            newDependency: '', // Nuevo campo para la dependencia a agregar
            dependencies: [], // Lista de dependencias agregadas
            history: [],
            datesearch: new Date().toISOString().slice(0, 10),
            ipserver: null,
            showModal: false,
            selectedResult: [], // Almacena el resultado seleccionado para el modal
            currentPage: 1, // Página actual
            itemsPerPage: 7, // Registros por página
            isLoading: false // Variable para el estado de carga
        };
    },
    methods: {
        async load() {
            this.isLoading = true; // Activar ícono de espera
            try {
                const response = await axios.get(`${this.$root.serverUrl}/admin/bingSearch/history`);
                console.log('Datos recibidos de la consulta:', response.data);
                this.searchResults = [];
                this.searchResults = response.data;
                console.log('searchResults actualizado:', this.searchResults);
                console.log('history actualizado:', this.history);
            } catch (error) {
                console.error('Error en la búsqueda:', error);
            } finally {
                this.isLoading = false; // Desactivar ícono de espera
            }
        },
        async view() {
            this.isLoading = true; // Activar ícono de espera
            try {
                const response = await axios.get(`${this.$root.serverUrl}/admin/bingSearch/list`);
                console.log('Datos recibidos de la consulta:', response.data);
                this.selectedResult = [];
                this.selectedResult = response.data;

                console.log('searchResults actualizado:', this.selectedResult);
            } catch (error) {
                console.error('Error en la búsqueda:', error);
            } finally {
                this.isLoading = false; // Desactivar ícono de espera
            }
        },
        async filter() {
            this.isLoading = true; // Activar ícono de espera
            let query = this.filters.search.trim();
            if (this.newDependency.length > 0) {
                query = `${this.newDependency}`;
            }
            if (query) {
                const url = '/api/v1/realtime/serp';
                const username = process.env.VUE_APP_AUTH_USER_NIMBLE;
                const password = process.env.VUE_APP_AUTH_PASSWD_NIMBLE;
                const headers = {
                    'Authorization': `Basic ${Buffer.from(`${username}:${password}`).toString('base64')}`,
                    'Content-Type': 'application/json'
                };
                const data = {
                    "search_engine": "bing_search",
                    "query": query
                };

                try {
                    const response = await axios.post(url, data, {headers});
                    if (response.status === 200 && response.data) {
                        console.log("200");
                        console.log(response.data);
                        const todo = {
                            ...response.data,
                            search: this.newDependency,
                            regdate: this.datesearch
                        };

                        await axios.post(`${this.$root.serverUrl}/admin/bingSearch/add`, todo);
                        this.load();
                        console.log('Resultados insertados en la base de datos.');
                    }

                } catch (error) {
                    console.error('Error en la búsqueda o inserción:', error);
                } finally {
                    this.isLoading = false; // Desactivar ícono de espera
                }

            } else {
                alert('Please, input search criteria');
                this.isLoading = false; // Desactivar ícono de espera en caso de error
            }
        },
        async viewDetails(id) {
            this.isLoading = true; // Activar ícono de espera
            try {
                const response = await axios.get(`${this.$root.serverUrl}/admin/bingSearch/details`, {
                    params: {id}
                });

                const jsonString = JSON.stringify(response.data[0].parsing.entities.OrganicResult);

                const jsonArray = JSON.parse(jsonString);

                if (Array.isArray(jsonArray)) {
                    // Iterar sobre cada objeto en el array
                    jsonArray.forEach(obj => {
                        console.log(JSON.stringify(obj, null, 2));
                    });
                } else {
                    console.error('El JSON parseado no es un array');
                }

                console.log("testeando las rutas " + jsonArray);

                console.log('Datos recibidos de la consulta:', response.data);
                this.selectedResult = [];
                this.selectedResult = jsonArray;
                console.log('selectedResult actualizado:', this.selectedResult);

                this.showModal = true;
            } catch (error) {
                console.error('Error al obtener los detalles:', error);
            } finally {
                this.isLoading = false; // Desactivar ícono de espera
            }
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        clearModal() {
            this.selectedResult = [];
        }
    },
    computed: {
        paginatedResults() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.searchResults.slice(start, end);
        },
        totalPages() {
            return Math.ceil(this.searchResults.length / this.itemsPerPage);
        }
    },
    created() {
        this.load();
    }
};
</script>

<style scoped>
/* Estilos CSS específicos para este componente */

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.text-center {
    text-align: center;
}

.search-container {
    background-color: white;
    padding: 2%;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.results-container {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.search-results {
    display: block;
}

.result-item {
    margin-bottom: 20px;
}

.result-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1px;
}

.result-date {
    font-size: 15px;
    margin-right: 20px;
}

.result-info {
    flex-basis: 70%;
}

.result-info-inner {
    display: flex;
    align-items: center;
}

.result-name {
    font-size: 15px;
    margin-right: 20px;
}

.result-button {
    margin-left: auto;
}

.loading-icon {
    margin: 20px 0;
}

.photos-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.photo-thumbnail {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
}

.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
